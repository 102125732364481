.article-list {
    padding: 10px;
}

.article-list h2 {
    color: var(--primary-color);
    margin-bottom: 20px;
}

.article-list table {
    width: 100%;
    border: 1px var(--primary-color) solid;
    border-collapse: separate;
    border-left: 0;
    border-radius: 4px;
    border-spacing: 0px;
}


thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
    border-collapse: separate;
}

tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}

th,
td {
    padding: 5px 4px 6px 4px;
    text-align: left;
    vertical-align: top;
    border-left: 1px var(--primary-color) solid;
}

tr th {
    color: var(--primary-color);
    /* display: flex; */
    /* justify-content: center; */
    text-align: center;
}

td {
    border-top: 1px var(--primary-color) solid;
    text-align: center;
}

thead:first-child tr:first-child th:first-child,
tbody:first-child tr:first-child td:first-child {
    border-radius: 4px 0 0 0;
}

thead:last-child tr:last-child th:first-child,
tbody:last-child tr:last-child td:first-child {
    border-radius: 0 0 0 4px;
}

td button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    transition: 0.2 all ease;
}

td button:hover {
    transform: scale(1.1);
}

td button i {
    color: var(--primary-color);
}













/* 

table,
th,
td {
    padding: 5px;
    border: 1px solid rgb(119, 119, 119);
    border-collapse: collapse;
}

th {
    color: var(--primary-color);
}





table tr:last-child td:last-child {
    border-bottom-right-radius: 20px;
}

table tr:last-child td:first-child {
    border-bottom-left-radius: 20px;
}
*/

@media (max-width: 1000px) {
    .article-list .card-content {
        overflow: scroll;
    }
}