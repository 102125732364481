.modal {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    padding: 10px;
    transition: .1s all ease;
    visibility: hidden;
    opacity: 0;
}

.modal.show {
    visibility: visible;
    opacity: 1;
}

.modal-block-touch {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #292929;
    opacity: 0.5;
}

.modal-content {
    background: #fff;
    width: 100%;
    max-width: 700px;
    max-height: 100%;
    border-radius: 8px;
    box-shadow: 0px 0px 18px 0px #000000b0;
    /* height: 50px; */
    z-index: 2000;
    transform: scale(0.5);
    transition: .2s all ease;
    padding: 10px;
}

.modal-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 10px; */
    margin-bottom: 20px;
    padding-top: 10px;
}

.modal-header button {
    background: none;
    border: none;
    padding: 0;
}

.modal-header button i {
    font-size: 26px;
}

.modal.show .modal-content {
    transform: scale(1);
}












.article {
    padding: 40px 0;
    font-size: 18px;
}

.article .container {
    padding: 0 10px;
}

.article-header p {
    margin: 0;
}

.article-tag {
    color: var(--primary-color);
    text-transform: uppercase;
}

.article-body {
    line-height: 170%;
    margin-bottom: 30px;
}

.article-title {
    font-size: 62px;
    margin-bottom: 20px;
    font-weight: bold;
    line-height: 110%;
}

.article-subtitle {
    color: var(--secondary-font-color);
}

.article-info {
    padding: 20px 0;
}

.article-author {
    font-size: 14px;
    font-weight: bold;
    color: var(--primary-color);
}

.article-date {
    font-size: 12px;
    color: var(--primary-font-color);
}


.article-banner {
    width: 100%;
    margin-bottom: 20px;
}

.article-banner img {
    width: 100%;
}

.article-body p {
    margin-bottom: 20px;
}

.article-body p img {
    width: 100%;
    max-width: 950px;
}

.article-related-title,
.article-share-title {
    color: var(--primary-color);
    font-weight: bold;
}

.article-related {
    margin-bottom: 20px;
}

.article-related-tags {
    display: flex;
    gap: 20px;
    width: 100%;
    overflow: hidden;
}

.article-related-tags a {
    border: 1px var(--primary-color) solid;
    padding: 8px 15px;
    border-radius: 8px;
    text-transform: uppercase;
    color: var(--primary-color);
    font-weight: bold;
    font-size: 14px;
}

.article-related-tags a:hover {
    background: var(--primary-color);
    color: var(--light-color);
}

@media (max-width: 800px) {
    .article-title {
        font-size: 40px;
    }
}