.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: none;
    z-index: 999;
}

.loading .loading-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    /* background: #fff; */
    background: #292929;
    z-index: 999;
    opacity: 0.5;
}

.loading.show {
    display: flex;
}

.spinner {
    border: 6px solid var(--primary-color);
    border-top-color: transparent;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    animation: is-rotating 1s infinite;
    z-index: 1000;
}

@keyframes is-rotating {
    to {
        transform: rotate(1turn);
    }
}