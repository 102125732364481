* {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans';
    box-sizing: border-box;
    color: #333333;
}

:root {
    --primary-font-color: #666666;
    --secondary-font-color: #555555;
    --light-font-color: #555555;
    --primary-color: #6a2c70;
    --light-color: #fff;
    --dark-color: #000;
    --background: #f7f7f7
}

body {
    background: var(--background);
}

button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px var(--primary-color) solid;
    border-radius: 5px;
    padding: 7px 10px;
    font-weight: bold;
    color: var(--light-color);
    /* height: 32px; */
    transition: all 0.2s ease;
    background: var(--primary-color);
    /* height: 36px; */
    font-size: 15px;
}

button.light {
    background: var(--light-color);
    color: var(--primary-color);
}

input {
    /* height: 32px; */
    background: var(--light-color);
    width: 100%;
    border: 1px var(--primary-color) solid;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 15px;
    padding: 7px 10px;
    color: var(--primary-color);
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 7px 10px;
    border-radius: 5px;
    color: var(--primary-color);
    width: 100%;
    border: 1px var(--primary-color) solid;
    background: var(--light-color);
    display: block;
    font-size: 15px;
    margin-top: 10px;
}

.card {
    background: #fff;
    padding: 10px;
    border-radius: 10px;
}

.card-title {
    color: var(--primary-color);
    font-weight: bold;
    padding-bottom: 20px;
    /* margin-bottom: 20px; */
}

.card-buttons {
    padding-top: 10px;
}

.card-buttons button {
    width: 100%;
    max-width: 200px;
}


.ql-container.ql-snow {
    border: 1px var(--primary-color) solid;
    border-radius: 0 0 8px 8px;

}

.ql-toolbar.ql-snow {
    border: 1px var(--primary-color) solid;
    border-radius: 8px 8px 0 0;
}

/* CONTAINER */

.container {
    padding-left: 250px;
    transition: .2s all ease;
}


@media (max-width: 800px) {
    .container {
        padding-left: 0;
    }
}



/* ARTICLE STYLES */

.article-creation {
    padding: 20px;
}

.article-creation h2 {
    color: var(--primary-color);
    margin-bottom: 20px;
}

.content-buttons {
    display: flex;
    gap: 10px;
    padding-top: 5px;
}

.article-content-box {
    width: 100%;
    height: calc(100vh - 170px);
}

.article-content-box button {
    width: 100%;
    background: var(--primary-color);
    color: #fff;
}

.article-content-box button:hover {
    background: #491f4d;
}

.article-content {
    border: 1px var(--primary-color) solid;
    padding: 20px;
    border-radius: 5px;
    background: #fff;
    overflow-wrap: break-word;
    overflow-y: scroll;
    height: calc(100% - 40px);
    font-size: 10px;
    width: 100%;
    margin-bottom: 10px;
}

.article-info p {
    font-size: 8px;
}

.input-box {
    display: block;
}

.input-box label {
    color: var(--primary-color);
}

.input-box .input-content-box {
    margin-top: 10px;
}

.input-box .input-content {
    background: #fff;
    /* margin-bottom: 20px; */
    font-size: 15px;
}

.article-title {
    font-size: 30px;
}

.row {
    display: flex;
    gap: 10px;
    width: 100%;
    margin-bottom: 10px;
}

.row div {
    width: 100%;
}

@media (max-width: 1300px) {

    .article-creation {
        padding: 10px;
    }

    .create-article-content {
        min-width: auto;
    }

    .article-content {
        padding: 10px;
    }

    .row {
        display: block;
    }

    .row.prevent {
        display: flex;
    }
}