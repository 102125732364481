.login {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background: var(--primary-color);
}

.login-form {
    position: relative;
    width: 100%;
    max-width: 450px;
    background: var(--light-color);    
    border-radius: 8px;
    min-height: 470px;
    box-shadow: 0px 0px 117px 20px #431e47;
}

.login-form-box {
    border: 2px solid var(--primary-color);
    height: 100%;
    box-sizing: border-box;
    padding: 30px 50px;
    position: absolute;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    top: 4px;
    left: 4px;
    border-radius: 8px;
    /* z-index: 0; */
    /* color: green; */
}
/* 
.login-form .input-box {
z-index: 1;
} */

.login-form-header {
    margin-bottom: 70px;
    padding: 10px 40px;
}

.login-description {
    margin-bottom: 20px;
    width: 100%;
}

.login-description span {
    font-weight: bold;

    /* font-size: 18px; */
    width: 100%;
    display: flex;
    justify-content: center;
    color: var(--primary-color);
}

.login-error-message {
    width: 100%;
    display: block;
    font-size: 13px;
    font-weight: bold;
    padding-bottom: 10px;
}

.login-error-message span {
    color: red;
}

.login-button {
    /* margin-bottom: 20px; */
    margin-top: 10px;
    width: 100%;
    height: 36px;
    font-size: 15px;
}

@media (max-width: 800px) {
    .login-form-box {
        padding: 30px 30px;
    }

}