.header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
    height: 70px;
    width: 100%;
    background: var(--light-color);
}

.header .open-menu-btn {
    background: none;
    border: none;
    display: none;
    padding: 0;
}

.header .open-menu-btn i {
    color: var(--primary-color);
    font-size: 35px;
}

/* USER-FIELDS */
.user-fields {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    padding: 10px;
    gap: 10px;
}

.user-fields span {
    font-weight: bold;
    color: var(--primary-color);
}

.user-fields button {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    overflow: hidden;
    border: 2px var(--primary-color) solid;
}

.user-fields button img {
    width: 32px;
}


/* MENU */

.menu {
    position: fixed;
    height: 100%;
    background: var(--primary-color);
    width: 250px;
    top: 0;
    left: 0;
    overflow: hidden;
    transition: .2s all ease;
    z-index: 500;
}

.menu .menu-header {
    display: flex;
    align-items: center;
    height: 70px;
    margin-bottom: 20px;
}

.menu .logo {
    padding: 0 20px;
}

.menu .menu-header .close-menu-btn {
    display: none;
    border: none;
    background: none;
}

.menu .menu-header .close-menu-btn i {
    color: var(--light-color);
    font-size: 35px;
}

.menu .logo svg {
    width: 100%;
}

.menu .menu-items {
    padding: 0 10px;
}

.menu .menu-items li {
    display: flex;
    cursor: pointer;
    border-bottom: 1px #ffffff70 solid;
}

.menu .menu-items li:hover {
    background: #551d5b;
}

.menu .menu-items li.active {
    background: #ffffff70;
}

.menu .menu-items li a {
    padding: 10px 10px;
    width: 100%;
    text-decoration: none;
    color: var(--light-color);
}


@media (max-width: 800px) {
    .header {
        justify-content: space-between;
    }

    .header .open-menu-btn {
        display: flex;
    }

    .menu {
        transform: translateX(-250px);
    }

    .menu.active {
        transform: translateX(0);
    }

    .menu .menu-header .close-menu-btn {
        display: flex;
    }

    .menu .logo {
        padding: 0 10px;
        height: 70px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .user-fields {
        padding: 0;
    }
}
